const client = 'descontos.pt';
//const client = 'staging.clickprofit.pt';
const tokens = {
	VERSION: 'version 1.37 WIDGET',
	ACCOUNTSID: '',
	FLEXFLOWSID: '',
	AU: '',
	APIURL: '',
	TIMELIMITBEGIN: 0,
	TIMELIMITEND: 0,
	LANGUAGE: '',
	PHONEBASE: '',
};

function getConstants() {
	switch(client) {
	  case 'descontos.pt':
	  	tokens.ACCOUNTSID = 'AC719f82c305e87a55cb04119b7f080b64';
	  	tokens.FLEXFLOWSID = 'FO64286d9714e93e4a177c0104c8ffbe24';
	  	tokens.AU = 'UA-142569055-1';
	  	tokens.APIURL = 'https://twilio-widget-api-php.herokuapp.com/';
	  	tokens.TIMELIMITBEGIN = 9;
	  	tokens.TIMELIMITEND = 18;
	  	tokens.LANGUAGE = 'PT_pt';
	  	tokens.PHONEBASE = '308 811 448';
	    return tokens;
	    break;
	  case 'staging.clickprofit.pt':
	    tokens.ACCOUNTSID = 'AC6a7d49dd8378b489fc42f6f8759973f4';
	  	tokens.FLEXFLOWSID = 'FOc1d9b0d9508ac2a66ad95f897bdf249d';
	  	tokens.AU = 'UA-142569055-1';
	  	tokens.APIURL = 'https://twilio-widget-api-php-demo.herokuapp.com/';
	  	tokens.TIMELIMITBEGIN = 0;
	  	tokens.TIMELIMITEND = 24;
	  	tokens.LANGUAGE = 'EN_us';
	  	tokens.PHONEBASE = '308 804 969';
	    return tokens;
	    break;
	  default:
	    return tokens;
	}
}

export default getConstants;